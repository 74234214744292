<template>
  <b-row>
    <b-col
      xs="12"
      md="6"
    >
      <b-card title="Hoşgeldiniz">
        <p>Eurocam - Online Tahsilat sistemine hoş geldiniz.</p>
        <p>Online tahsilat sistemi, banka kartı veya kredi kartınız ile güvenli ödeme işlemi yapabilirsiniz.</p>
        <b-button
          variant="primary"
          to="payment/customer-search"
        >
          <FeatherIcon icon="CreditCardIcon" />
          Ödeme Sayfasına Git
        </b-button>
      </b-card>
    </b-col>
    <b-col
      xs="12"
      md="6"
    >
      <b-img
        :src="imgUrl"
        fluid
      /></b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BImg,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  name: 'DashboardIndex',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BImg,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/images/pages/home.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties,global-require
        this.sideImg = require('@/assets/images/pages/home-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
}
</script>
